<script setup lang="ts">
import { type Validation } from '@vuelidate/core'
import { McFormSubmit, vKey, formNameKey } from '~/lib/interfaces/profile'
import { ToastType } from '~/lib/models/ui/toast'
import { GTMFormNameType, GTMFormNameTypes } from '~/lib/models/gtm'

const { $toast } = useNuxtApp()
const { trackFormSubmit, trackFormError } = useGTM()

const props = defineProps<{
  v?: Validation<any, any>
  disabled?: boolean
  onSubmit?: McFormSubmit
  formName?: GTMFormNameTypes | undefined
}>()

const submitting = ref(false)
const formName = props.formName || GTMFormNameType.PROFILE

provide(vKey, props.v)
provide(formNameKey, formName)

async function onSubmitting() {
  // track event form_submit
  if (!props.disabled && props.v && !props.v.$invalid) trackFormSubmit(formName)

  if (props.disabled || !props.onSubmit) return

  if (props.v && props.v.$invalid) {
    props.v.$touch()
    return
  }

  if (submitting.value) return
  submitting.value = true

  try {
    const toastOptions = await props.onSubmit()
    if (toastOptions)
      $toast.show({
        ...toastOptions,
      })
  } catch (err) {
    $toast.show({
      type: ToastType.ERROR,
      content: (err as Error).message,
    })
    trackFormError({
      type: formName,
      formName,
    })
  }
  submitting.value = false
  props.v?.$reset()
}
</script>

<template>
  <form @submit.prevent="onSubmitting">
    <slot v-bind="{ submitting }" />
  </form>
</template>
